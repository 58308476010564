document.addEventListener("turbolinks:load", function () {
  $("[id^=remove-product-file-]").on("click", function () {
    if ("value" in $("#product_images_to_purge")[0].attributes) {
      var currentString = $("#product_images_to_purge")[0].attributes.value
        .value;
      var stringToAdd = "," + $(this)[0].attributes.value.value;
      $("#product_images_to_purge").val(currentString.concat(stringToAdd));
    } else {
      $("#product_images_to_purge").val($(this)[0].attributes.value.value);
    }
    $(this).parent().parent().remove();
  });
});

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("trix");
require("@rails/actiontext");

// Custom JS files
require("custom/archived_downloads_button");
require("custom/arrow");
require("custom/element_link");
require("custom/file_field");
require("custom/navbar");
require("custom/product_gallery");
require("custom/remove_file");
require("custom/remove_product_file");
require("custom/scrollable");

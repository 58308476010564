document.addEventListener("turbolinks:load", function() {
  $("#archived-downloads-button").on("click", function() {
    var $this = $(this);
    $this.toggleClass('show-downloads');
    if($this.hasClass('show-downloads')){
        $this.text('Hide Archived Downloads');
    } else {
        $this.text('Show Archived Downloads');
    }
  })
})

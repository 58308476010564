document.addEventListener("turbolinks:load", function() {
  $(".scrollable").each(function() {
    checkScroll(this)
    $(this).on("scroll", function() {
      checkScroll(this)
    })
  })

  function checkScroll(element){
    if ($(element).scrollTop() == $(element)[0].scrollHeight - $(element).outerHeight()) {
      $(element).removeClass("scrollable-shadow")
    } else {
      $(element).addClass("scrollable-shadow")
    }
  }
})
